//
// Styles for main layout
// --------------------------------------------------
body {
  // Welcome Section
  #ww {
    padding-top: 70px;
    padding-bottom: 70px;
    background-color: #f2f2f2;
  }

  // Footer
  #footer {
    background-color: #2f2f2f;
    padding-top: 50px;
    padding-bottom: 50px;
    margin: 0;
    color: white;
    p {
      color: white;
    }
    h4 {
      color: white;
      text-transform: uppercase;
      padding-bottom: 20px;
    }
  }

  #grey,
  .grey {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #f2f2f2;
  }

  #white,
  .white {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #ffffff;
  }
}
