// Menu
// Styles for Menus and Navs
// --------------------------------------------------

// Navs
// --------------------------------------------------
.nav {
  .footer & {
    li {
      margin-bottom: 5px;
      a {
        font-size: 16px;
        padding: 0;
        &:hover, &:focus, &.active {
          background: none;
        }
      }
    }
  }
}