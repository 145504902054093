//
// Styles for Forms
// --------------------------------------------------

// General styles
// --------------------------------------------------
form {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  label {
    color: $gray-light;
  }
}
