// Styles for Views
// --------------------------------------------------

// Fixes
// --------------------------------------------------
.view {
  .row {
    > .list-group {
      @extend .col-md-12;
    }
  }
}
.views-exposed-form {
  .views-submit-button,
  .views-reset-button {
    margin-top: 3px;
  }
}
