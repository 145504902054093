//
// Styles for comment.
// --------------------------------------------------

// General style
// --------------------------------------------------
.comment {
  .submitted {
    img {
      width: 50px;
      height: auto;
    }
  }
  .author {
    font-size: 12px;
    text-transform: uppercase;
  }
  .date {
    font-size: 12px;
    text-transform: uppercase;
    color: #d2d2d2;
    font-weight: 700;
  }
  .content {
    .field {
      margin: 0 0 10px;
    }
  }
  .field-label {
    font-size: 12px;
    font-weight: normal;
  }
}

#comments,
.comment-wrapper {
  position: relative;
  margin-top: 30px;
  padding-top: 30px;
  h2 {
    margin-bottom: 15px;
  }
}

.comment {
  margin: 0 0 40px;
  padding: 0 0 40px;
  position: relative;
  &:after {
    position: absolute;
    margin: 0 auto;
    height: 1px;
    width: 50%;
    border-bottom: 1px solid #eeeeee;
    visibility: visible;
    bottom: 0;
    left: 50%;
    margin-left: -25%;
  }
}
