//
// Styles for Tabs, Messages, ..etc
// --------------------------------------------------

// Logo
// --------------------------------------------------
#logo {
  margin-right: 10px;
}

// Messages
// --------------------------------------------------
#messages {
  margin-top: 18px;
}

// Tabs
// --------------------------------------------------
#tabs {
  margin: 18px auto;
  display: table;
}

// Page header
// --------------------------------------------------
.page-header {
  padding-bottom: 20px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  h1 {
    text-transform: uppercase;
    font-size: 30px;
    line-height: 120%;
  }
}

// Navbar
// --------------------------------------------------
.navbar {
  text-transform: uppercase;
  margin-bottom: 0px;
  z-index: 500;
  &.navbar-inverse {
    padding-bottom: 70px;
    padding-top: 70px;
    background-color: $brand-primary;
    border-color: #16a085;
    .navbar-nav {
      > li {
        > a {
          color: white;
          &:hover, &:focus, &.active {
            color: #222222;
            background-color: transparent;
          }
        }
      }
    }
    .navbar-brand {
      font-weight: 700;
      font-size: 20px;
      letter-spacing: 2px;
      color: white;
    }
    .navbar-toggle {
      border-color: transparent;
    }
    .navbar-collapse {
      border: 0;
      @include box-shadow(none);
    }
  }
}

// Progress bar
// --------------------------------------------------
.progress {
  margin-bottom: 20px;
  height: 20px;
  overflow: hidden;
}

// Zoom
// --------------------------------------------------
.zoom { 
  display: block;
  position: relative;
  overflow: hidden;
  background: transparent url("../images/loader.gif") no-repeat center;
  img {
    display: none;
  }
}
.zoomOverlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
  background-image: url("../images/zoom.png");
  background-repeat: no-repeat;
  background-position: center;
}
.zoom2 {
  opacity: 1;
}
.zoom2:hover {
  opacity: 0.5;
}

// Links
ul.links {
  > li {
    display: inline;
    margin: 0 !important;
    &:after {
      content: "-";
      padding: 0 3px;
    }
    &:last-child {
      &:after {
        content: "";
      }
    }
  }
}
