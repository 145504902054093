//
// Styles for Nodes
// --------------------------------------------------

// General style
// --------------------------------------------------
.node {
  .submitted {
    .user-picture {
      float: left;
      margin-right: 10px;
    }
    img {
      width: 50px;
      height: auto;
    }
  }
  .author {
    font-size: 12px;
    margin-top: 10px;
    text-transform: uppercase;
  }
  .date {
    font-size: 12px;
    text-transform: uppercase;
    color: #d2d2d2;
    font-weight: 700;
  }
  .content {
    .field {
      margin: 0 0 15px;
    }
  }
  .field-label {
    font-size: 12px;
    font-weight: normal;
  }
}

// Tags
.field-name-field-tags {
  font-size: 12px;
  .field-label,
  .field-items {
    display: inline-block;
    margin: 0 5px 0 0;
  }
  .field-item {
    display: inline-block;
    &:after {
      content: "-";
      padding: 0 3px;
    }
    &:last-child {
      &:after {
        content: "";
      }
    }
  }
}
bd {
  font-size: 12px;
  text-transform: uppercase;
  color: #d2d2d2;
  font-weight: 700;
}
ba {
  font-size: 12px;
  text-transform: uppercase;
}
.bq {
  font-size: 22px;
  padding-top: 30px;
}
bt {
  font-size: 12px;
}

// Article
// --------------------------------------------------
.node-article {
  // Teaser
  &.node-teaser {
    margin: 0 0 40px;
    padding: 0 0 40px;
    position: relative;
    &:after {
      position: absolute;
      margin: 0 auto;
      height: 1px;
      width: 50%;
      border-bottom: 1px solid #eeeeee;
      visibility: visible;
      bottom: 0;
      left: 50%;
      margin-left: -25%;
    }
  }
  .field-type-image {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
