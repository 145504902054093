//
// Font
// --------------------------------------------------
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
}

// Titles
// --------------------------------------------------
h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #333;
}
h1 {
  font-size: 35px;
  margin-top: 30px;
  margin-bottom: 30px;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

// Paragraph & Typographic
// --------------------------------------------------
p {
  line-height: 28px;
  margin-bottom: 25px;
  font-size: 16px;
}
.centered {
  text-align: center;
}

// Links
// --------------------------------------------------
a {
  word-wrap: break-word;
  @include transition(color 0.1s ease-in, background 0.1s ease-in);
  &:hover, &:focus {
    text-decoration: none;
    outline: 0;
  }
  &:before, &:after {
    @include transition(color 0.1s ease-in, background 0.1s ease-in);
  }
}
